<template>
  <div>
    <!-- <v-row class="text-center">

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome
        </h1>

        <p class="subheading font-weight-regular">
          Here is a bunch of helpful stuff, look how helpful it is.
        </p>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <SearchEvent />
      </v-col>
      <v-col>
        <AddUser />
      </v-col>

    </v-row>
  </div>
</template>

<script>
  import SearchEvent from '../views/SearchEvent';
  import AddUser from '../views/AddUser';

  export default {
    name: 'HelloWorld',

    components: {
      SearchEvent,
      AddUser
    },
    data: () => ({
    }),
  }
</script>


