<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h2 class="display-1 font-weight-bold mb-1">
          Search for Event
        </h2>
      </v-col>
    </v-row>
    <SearchEvent />
  </v-container>
</template>

<script>
  import SearchEvent from '../components/SearchEvent';

  console.log('script started in components/Home')
  export default {
    name: 'SearchEventView',

    components: {
      SearchEvent
    },
  }
</script>
