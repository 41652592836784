<template>
    <v-container>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <h3>Paste URL or select from dropdown</h3>
            <v-row>
                <v-col cols="9">
                    <v-text-field
                        v-model="spaceId"
                        @keydown.space.prevent
                        hide-details 
                        validate="spaceRules"
                        label=""
                        placeholder=""
                        filled
                        dense
                        single-line
                        clear-icon="mdi-close-circle"
                        clearable/>
                </v-col>
                <v-col md="2">
                    <v-btn
                        color="primary"
                        v-on:click="callguestlist( spaceId )"
                        >
                        Search
                    </v-btn>
                </v-col>
                <v-col md="1">
                    <v-btn
                        color="primary"
                        v-on:click="getSpaceList()"
                        >
                        List
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="9">
                    <v-select v-model="spaceId"
                            :items="spacelist"
                            :rules="[v => !!v || 'Item is required']"
                            label="Item"
                            required/>
                </v-col>
                <!-- <v-col md="2">
                    <v-btn  
                        color="primary"
                        v-on:click="callguestlist( spaceId )"> 
                        SEARCH
                    </v-btn>
                </v-col> -->
                <v-col md="2">
                    <v-btn class="mr-4"
                        v-on:click="clearresults()"
                        color="error"
                        :disabled="!showsearchbox"
                        >
                        Clear Results
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>


        <v-row class="text-center">
        <v-col
            v-if="showsearchbox"
            class="mb-5"
            cols="12"
        >
            <v-row justify="center">
                    <v-card>
                        <v-card-text class="headline font-weight-bold mb-3">
                            Results for {{lastsearchedevent}}
                        </v-card-text>
                        <v-col>
                            <v-btn class="mr-4">
                                <vue-blob-json-csv color="primary"
                                @success="handleFileSuccess"
                                @error="handleFileError"
                                tag-name="div"
                                file-type="json"
                                :file-name="filename"
                                title="Download JSON"
                                :data="guestlistgroomed"
                                ></vue-blob-json-csv>
                            </v-btn>
                            <v-btn class="mr-4">
                                <vue-blob-json-csv
                                    @success="handleFileSuccess"
                                    @error="handleFileError"
                                    tag-name="div"
                                    file-type="csv"
                                    :file-name="filename"
                                    title="Download CSV"
                                    :data="guestlistgroomed"
                                    
                                ></vue-blob-json-csv>
                            </v-btn>
                        </v-col>
                    <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="guestlistflattened"
                    :search="search"
                    ></v-data-table>
                </v-card>
            </v-row>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { API } from 'aws-amplify'

export default {
    data: () => ({
        spacelist: [
            'dwGp0ZmtENKpFmHD\\dstankspace',
        ],
        spaceId : "",
        spaceRules: [
          v => !!v || 'Name is required',
          v => /^[a-zA-Z0-9]{16}[\\/]{1}[a-zA-Z0-9]/.test(v) || "Must be 16 alphanumeric followed by slash followed by name"
        ],
        search: '',
        headers: [
          { text: 'Email', align: 'start', value: 'email',},
          { text: 'Name', value: 'name' },
          { text: 'Affiliation', value: 'affiliation' },
          { text: 'Role', value: 'role' },
        ],
        guestlist : [],
        guestlistflattened : [],
        showsearchbox: false,
        lastsearchedevent: "",
        lastsearchcount: 0,
        csvgroomingorder:["email","name","affiliation","role"],
        guestlistgroomed: [],
        filename: "",
        valid:false
    }),
    methods: {
        clearresults: function(){
            this.showsearchbox=false;
            this.guestlist=false;
            this.guestlistflattened=[]
            this.guestlistgroomed=[]
            this.filename=""
        },
        flatten: function(gl){
            const guestlistflat = []
            Object.keys(gl).forEach((key) => {
                const inner= gl[key];
                inner['email']=key;
                guestlistflat.push(inner)
            });
            return guestlistflat;
        },
        groomforcsv: function(gl){
            const guestlistgroomed = [];
            gl.forEach((guest) => {
                // console.log(guest)
                const inner= {}
                this.csvgroomingorder.forEach((key) => {
                    // console.log(key," ::: ", guest[key])
                    inner[key]=guest[key];
                })
                guestlistgroomed.push(inner)
            });
            // console.log("groomed guestlist: ",guestlistgroomed)
            return guestlistgroomed;
        },
        callguestlist: async function(spaceId) {
            try {
                console.log("space Id:'"+this.spaceId+"'")
                const response = await API.post('gatherApi','/guestlist',{body: {spaceId: spaceId.replace("/","\\")}});
                // console.log(response);
                this.guestlist =response.guestlist
                this.guestlistflattened = this.flatten(response.guestlist);
                this.guestlistgroomed = this.groomforcsv(this.guestlistflattened);
                this.lastsearchedevent=this.spaceId;
                this.showsearchbox=true;
                this.filename = spaceId.replace("/[\\/]","-"); //replace slashes with hyphen, the library just strips out extra junk?
            } catch (error) { 
                console.log(error);
                this.showsearchbox=true;
            }
        },
        getSpaceList: async function() {
            try {
                console.log("Retrieving list of spaces")
                const response = await API.get('gatherApi','/space');
                console.log(response);
                this.spacelist =response.spacelist
            } catch (error) { 
                console.log(error);
                this.showsearchbox=true;
            }
        },
        handleFileSuccess: function(){
           console.log("file exported")
        },
        handleFileError: function(){
        console.log("error exporting file")
        },
    }
}
  
</script>