<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/HZSg-HOn_400x400.jpg"
          transition="scale-transition"
          width="40"
        />

      </div>

      <v-spacer ></v-spacer>
      <v-tabs
        v-if="signedIn"
        centered
        class="ml-n9"
        color="white darken-1"
      >
        <v-tab key="home" to=Home>Home</v-tab>
        <v-tab key="searcheventview" to="/searcheventview">Search Event</v-tab>
        <v-tab key="adduserview" to="/adduserview">Add User</v-tab>
      </v-tabs>
      <v-btn v-if="signedIn" to=Profile text>
        <span class="mr-2">{{usermoniker}}</span>
      </v-btn>
      <amplify-sign-out v-if="signedIn"></amplify-sign-out>



      
    </v-app-bar>

    <v-main>
      <!-- <amplify-authenticator></amplify-authenticator> -->
      <AuthComponent v-if="!signedIn"></AuthComponent>
      <!-- <HelloWorld v-if="signedIn"></HelloWorld> -->
      <router-view v-if="signedIn"></router-view>
    </v-main>
  </v-app>
</template>

<script>

import { Auth, Hub } from 'aws-amplify'
// import Home from './views/Home'
// import HelloWorld from './components/HelloWorld';
import AuthComponent from './components/Auth';

console.log('script started in components/App')

export default {
  name: 'App',
  components: {
    //  HelloWorld,
     AuthComponent
  },
  beforeCreate() {
    if(this.user){
      this.usermoniker = this.user.attributes.email;
    }
    this.signedIn= false;
    Auth.currentAuthenticatedUser().then(user => {
          this.usermoniker = user.attributes.email;
          this.signedIn = true
          // console.log("currentAuthenticatedUser: ",user)
    })
    .catch(() => this.signedIn = false)
    Hub.listen('auth', data => {
      console.log('App.vue: Hub event auth detected:', data)
      const { payload } = data
      if (payload.event === 'signIn') {
        this.signedIn = true
        // this.$router.push(HelloWorld)
        Auth.currentAuthenticatedUser().then(user => {
          this.usermoniker = user.attributes.email;
          this.signedIn = true
          // console.log("signIn event: currentAuthenticatedUser: ",user)
        })
        .catch(() => this.signedIn = false)
      }
      if (payload.event === 'signOut') {
        // this.$router.push('/auth')
        this.signedIn = false
      }
    })
  },
  data: () => ({
    signedIn: false,
    usermoniker: "",
    user: null,
    links: [
        'Home',
        'Profile',
        'Search Event',
        'Add User',
      ]
  }),
};
</script>