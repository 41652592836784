<template>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="space"
              :rules="spaceRules"
              label="Space"
              required
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              @click="callsetguestlist"
            >
            Add User
            </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn
              color="error"
              class="mr-4"
              @click="reset"
            >
            Reset Form
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-row>
          <v-col md="4">
            <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Name"
            ></v-text-field>
          </v-col>
          <v-col  md="2">
            <v-text-field
            v-model="role"
            :rules="roleRules"
            label="Role"
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
            v-model="affiliation"
            :rules="affiliationRules"
            label="Affiliation"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
</template>

<script>
// const axios = require("axios");
import { API } from 'aws-amplify'

export default {
  data: () => ({
    valid: false,
    space: '',
    spaceRules: [
      v => !!v || 'Name is required',
      v => /^[a-zA-Z0-9]{16}[\\/]{1}[a-zA-Z0-9]/.test(v) || "Must be 16 alphanumeric followed by slash followed by name"
    ],
    name: '',
    nameRules: [],
    affiliation: '',
    affiliationRules: [],
    role: '',
    roleRules: [],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    checkbox: false,
    radios: null,
  }),
  methods: {
    callsetguestlist: async function() {
      // const URL_SET_GUESTLIST = "https://gather.town/api/setEmailGuestlist"
      
      let SPACE_ID = this.space.replace("/[\\/]","-") //"8JxRFeI4RpPLCVPa\\My Home Space";
      let API_KEY = "CcHUlWJ33CqShsC4";
      let EMAIL = this.email;
      // let NAME = this.name;
      // let ROLE = this.role;
      // let AFFILIATION = this.affiliation;
      let GUEST_ATTRIBUTES =['name','role','affiliation'];
      
      //built in anticipation of adding multiple users at once. List of email+attributes can be converted to format the API wants
      let formattributes={email:EMAIL}
      GUEST_ATTRIBUTES.forEach(a =>{
        if(this[a]){
          formattributes[a]=this[a]
        }
      });
      let gl=[formattributes]

      let glcomprehension = {}
      gl.forEach((g) => {
        let x = {}
        GUEST_ATTRIBUTES.forEach(a =>{if (g[a]){
          x[a]=g[a] //add the attribute if it exists
        }});
        glcomprehension[g.email] = x //return list off attributes keyed by email
      });

      console.log("Passing this to lambda >>>>  ",SPACE_ID," ... ", API_KEY, ">>> ",glcomprehension)

      try {// call to lambda function
        const response = await API.post('gatherApi','/user',{body: 
        {
          spaceId: SPACE_ID,
          apiKey: API_KEY,
          guestlist: glcomprehension
        }
      });
      console.log("back from successful call to gatherApi/user")
      console.log(response)
      } catch (error) 
      { console.log(error);
      alert("Direct call to API: "+error) }
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },

  },
  beforeCreate() {
    this.valid=false;
  }
}
</script>