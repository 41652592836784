<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-2">
          Add User
        </h1>
      </v-col>
    </v-row>
    <AddUser />
  </v-container>
</template>

<script>
import AddUser from '../components/AddUser';

export default {
    name: 'AddUserView',
    components: {
      AddUser
    },
}
</script>