<template>
    <amplify-authenticator></amplify-authenticator>
</template>

<script>
console.log('script started in components/Auth')
export default {name: 'auth'}
</script>

<style>
.auth {
  margin: 0 auto;
  width: 460px;
}
</style>