import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

// import Home from './views/Home'
import Home from './components/Home'
import AuthComponent from './components/Auth'
import Profile from './components/Profile'
import SearchEvent from './components/SearchEvent'
import SearchEventView from './views/SearchEvent'
import AddUser from './components/AddUser'
import AddUserView from './views/AddUser'

const routes = [
  { path: '/Home', component: Home},
  { path: '/auth', component: AuthComponent },
  { path: '/searchevent', component: SearchEvent, meta: { requiresAuth: true } },
  { path: '/searcheventview', component: SearchEventView, meta: { requiresAuth: true } },
  { path: '/adduser', component: AddUser, meta: { requiresAuth: true } },
  { path: '/adduserview', component: AddUserView, meta: { requiresAuth: true} },
  { path: '/profile', component: Profile, meta: { requiresAuth: true } }
  // { path: '*', component: Home },
]

const router = new VueRouter({
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser().then(() => {
      next()
    }).catch(() => {
      next({
        path: AuthComponent
      });
    });
  }
  next()
})

export default router