<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome, {{email}}
        </h1>
        <h2>aws cognito user {{username}}</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'Profile',
  data() {
    return {
      username: "",
      email: ""
    }
  },
  beforeCreate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.username = user.username
        this.email = user.attributes.email
        console.log(user)
      })
      .catch(() => console.log('not signed in...'))
  }
}
</script>