/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f2902bcd-319b-4e08-b224-b4419471491c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YnIIYqGMX",
    "aws_user_pools_web_client_id": "6d7459arr3cppaafp0e9mn8h33",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "gatherApi",
            "endpoint": "https://1l3i3a7fx9.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
